import { Options, Vue } from 'vue-class-component';
import {
  EthereumNetwork,
  EthereumProvider
} from '@manifoldxyz/manifold-sdk';
import {
  AbstractProvider,
  ETHEREUM_NETWORK_COLORS
} from '@/common/constants';
import web3TransactionErrorHandling, { TransactionErrors } from '@/common/web3TransactionErrorHandling';

@Options({
  props: {
    fallbackProvider: String,
    network: EthereumNetwork,
    autoReconnect: {
      type: Boolean,
      default: true
    },
    overrideConnectText: {
      type: String,
      default: ''
    }
  },
  watch: {
    walletAddressFull: async function () {
      await this.updateBalance();
    }
  }
})
export default class WalletMixin extends Vue {
  fallbackProvider!: string|undefined;
  network!: EthereumNetwork;
  autoReconnect!: boolean
  overrideConnectText!: string

  badConfiguration: string | null | undefined = null;
  providerAvailable = false;
  walletAddressFull: string | undefined = '';
  walletAddressShort: string | undefined = '';
  walletENS: string | undefined = '';
  walletAvailable = !!(window && window.ethereum);
  walletBalance: string | undefined = '';
  walletConnected = false;
  wrongChain = false;
  chainInfo: {
    name: string,
    color: typeof ETHEREUM_NETWORK_COLORS
  } | null = null;

  isLoading = false;

  get buttonText () : string {
    return this.isLoading
      ? 'Logging in...'
      : this.overrideConnectText
        ? this.overrideConnectText
        : 'Connect Wallet';
  }

  get browserWalletName (): string {
    if (EthereumProvider.hasBrowserProvider()) {
      // should return browser provider if available
      const provider = EthereumProvider.provider() as AbstractProvider;
      if (provider.provider.isCoinbaseWallet) {
        return 'Coinbase';
      } else if (provider.provider.isBraveWallet) {
        return 'Brave';
      } else if (provider.provider.isLedgerConnect) {
        return 'Ledger Connect';
      } else if (provider.provider.isMetaMask) {
        return 'MetaMask';
      }
    }
    const userAgent = navigator.userAgent;
    if (userAgent.match(/chrome|chromium|crios/i)) {
      return 'Chrome';
    } else if (userAgent.match(/firefox|fxios/i)) {
      return 'Firefox';
    } else if (userAgent.match(/safari/i)) {
      return 'Safari';
    } else if (userAgent.match(/opr\//i)) {
      return 'Opera';
    } else if (userAgent.match(/edg/i)) {
      return 'Edge';
    }
    return 'MetaMask';
  }

  async created () : Promise<void> {
    window.manifold = {};
    // Get address by pulling information from EthereumProvider, because the widget may be created
    // AFTER the event is fired
    const address = EthereumProvider.selectedAddress();

    if (address) {
      // It means we connected before creating this widget
      this.providerAvailable = true;
      localStorage.setItem('connectedAddress', address);
      // Set up the wallet address state
      await this.onAddressChanged();
      await this.updateBalance();
    }

    window.addEventListener(EthereumProvider.ADDRESS_CHANGED, this.onAddressChanged);
    // We set up the provider and chain listeners afterwards to avoid
    // situations where a provider change can trigger a
    // concurrent auto-reconnect with the above.
    window.addEventListener(EthereumProvider.PROVIDER_CHANGED, this.onProviderChanged);
    window.addEventListener(EthereumProvider.CHAIN_CHANGED, this.onChainChanged);

    await this.updateChainInfo();
  }

  destroyed () : void {
    window.removeEventListener(EthereumProvider.PROVIDER_CHANGED, this.onProviderChanged);
    window.removeEventListener(EthereumProvider.ADDRESS_CHANGED, this.onAddressChanged);
    window.removeEventListener(EthereumProvider.CHAIN_CHANGED, this.onChainChanged);
  }

  async mounted () : Promise<void> {
    if (!this.network && this.fallbackProvider) {
      this.badConfiguration = 'Config Error';
      throw new Error('fallbackProvider should not be configured on network agnostic connections.');
    } else {
      if (!!EthereumProvider.network() && this.network !== EthereumProvider.network()) {
        console.warn(
          'An older EthereumProvider was initialized with different inputs, your input for the current connect-widget will be ignored'
        );
      }
      await EthereumProvider.initialize(this.network, this.fallbackProvider);

      const provider = EthereumProvider.provider();
      if (provider) {
        // We have a browser provider
        if (EthereumProvider.hasBrowserProvider()) {
          this.walletAvailable = true;
        }

        // Provider only available if no network or network is correct
        if (!EthereumProvider.selectedAddress()) {
          // No address and address listener setup
          // Run auto-reconnect, which will trigger the address
          // changed callback and set up the appropriate state
          await this._automaticallyReconnect();
        }
      }

      this.updateChainInfo();

      // whenever they complete a tx lets update the UX of their eth balance
      window.addEventListener('transactions-confirmed-event', async () => {
        await this.updateBalance();
      });
    }
  }

  /**
   * Handles button triggered connect
   */
  async connectWallet () : Promise<void> {
    try {
      this.isLoading = true;
      await this._connectWithEthereumProvider();
    } catch (error) {
      await this._disconnect(true);
      this.isLoading = false;
    }
  }

  /**
   * Connects to web3 and updates all chain/wallet related info upon success.
   */
  async _connectWithEthereumProvider (autoReconnect = false) : Promise<void> {
    try {
      await EthereumProvider.connect();
    } catch (error: any) { // eslint-disable-line  @typescript-eslint/no-explicit-any
      if (!autoReconnect) {
        const transactionErrors = web3TransactionErrorHandling(error);
        switch (transactionErrors) {
          case TransactionErrors.REJECTED: {
            await this._disconnect(true);
            break;
          }
          case TransactionErrors.LEDGER_ERROR: {
            await this._disconnect(true);
            break;
          }
          case TransactionErrors.PENDING: {
            alert(`Please open ${this.browserWalletName} Wallet to continue.`);
            break;
          }
          default: {
            alert(
              `Could not connect to ${this.browserWalletName}, please try refreshing your page. If you continue to have issues, try closing your browser and re-opening it.`
            );
            break;
          }
        }

        throw error;
      }
    }
  }

  /**
   * Disconnects from web3 and deletes our Oauth cookie for the JSON API.
   */
  async disconnectWallet () : Promise<void> {
    await this._disconnect();
  }

  /**
   * Disconnects from web3
   */
  async _disconnect (skipProviderDisconnect = false) : Promise<void> {
    this._disconnectBase(skipProviderDisconnect);
  }

  /**
   * This function should be called by any mixin that overrides _disconnect
   */
  async _disconnectBase (skipProviderDisconnect = false) : Promise<void> {
    if (this.walletConnected) {
      localStorage.removeItem('connectedAddress');
      this.walletAddressFull = undefined;
      this.walletAddressShort = undefined;
      this.walletENS = undefined;
      this.walletBalance = undefined;
      this.walletConnected = false;
      this.isLoading = false;
      window.manifold = {
        isAuthenticated: false,
        connectedAddress: ''
      };
      if (!skipProviderDisconnect) {
        await EthereumProvider.disconnect();
      }
    }
  }

  /**
   * Connects to web3 only if this.automaticallyReconnect is set and we
   * are already connected with a valid wallet address.
   */
  async _automaticallyReconnect () : Promise<void> {
    if (this.autoReconnect) {
      if (localStorage.getItem('connectedAddress')) {
        await this._connectWithEthereumProvider(true);
      }
    }
  }

  /**
   * Updates the current eth balance of the wallet being displayed.
   * Call this whenever the adddress, chain, or provider is changed.
   */
  async updateBalance () : Promise<void> {
    const provider = EthereumProvider.provider();
    if (this.walletAddressFull && provider) {
      let balanceString = (await provider.getBalance(this.walletAddressFull)).toString();
      const balanceParts = balanceString.split('.');
      if (balanceParts.length === 2 && balanceParts[1].length > 2) {
        balanceString = `${balanceParts[0]}.${balanceParts[1].slice(0, 2)}`;
      }
      this.walletBalance = balanceString;
    } else {
      this.walletBalance = undefined;
    }
  }

  /**
   * Updates the name and corresponding color for chainInfo. Call this
   * whenever the chain information may have updated.
   */
  updateChainInfo () : void {
    /* NOTE possibility that there is duplicate functionality here
     * between this method and badConfiguration variable.
     */
    const chainId: number | undefined = EthereumProvider.chainId();
    if (chainId) {
      this.chainInfo = {
        name: EthereumNetwork[chainId],
        color: ETHEREUM_NETWORK_COLORS[chainId]
      };

      this.wrongChain = !EthereumProvider.chainIsCorrect();
      this.badConfiguration = '';
      this.providerAvailable = true;
    } else {
      // No network provider or valid provider for specified network.
      // See if reason is due to browser chain being on the wrong network
      if (!EthereumProvider.chainIsCorrect()) {
        this.badConfiguration = 'Wrong Network';
        this.providerAvailable = false;
      }
    }
  }

  /**
   * Fires when the address is changed
   *
   * First it updates all chainInfo.
   * Then it updates everything related to the wallet adddress and ens name
   * if possible. Finally it stores the wallet address as "connectAddress"
   * inside of local storage. If ther ewas an issue or the address is now
   * undefined/null, we clear all wallet related vlaues and clear localStorage
   * of the "connectedAddress" itme.
   */
  async onAddressChanged () : Promise<void> {
    this.badConfiguration = null;
    this.updateChainInfo();
    const address = EthereumProvider.selectedAddress();
    const ens = EthereumProvider.selectedENSName();
    if (address !== this.walletAddressFull || ens !== this.walletENS) {
      // Only change if address has changed
      // Reset current state via disconnect
      await this._disconnect(true);

      this.walletAddressFull = address;
      this.walletENS = ens;
      if (address) {
        try {
          const addressLength = address.length;
          const retval = address.slice(0, 6) +
            '...' +
            address.slice(addressLength - 4, addressLength);
          this.walletAddressShort = retval;
          this.walletConnected = true;
          localStorage.setItem('connectedAddress', address);
        } catch (error: any) { // eslint-disable-line  @typescript-eslint/no-explicit-any
          const transactionErrors = web3TransactionErrorHandling(error);
          switch (transactionErrors) {
            case TransactionErrors.REJECTED: {
              await this._disconnect(true);
              break;
            }
            case TransactionErrors.LEDGER_ERROR: {
              await this._disconnect(true);
              break;
            }
            case TransactionErrors.PENDING: {
              alert(`Please open ${this.browserWalletName} Wallet to continue.`);
              break;
            }
            default: {
              alert('There was an issue with that wallet connection');
              break;
            }
          }
        }
      } else {
        // No address, no state
        await this._disconnect(true);
      }

      this.isLoading = false;
    }
  }

  /**
   * Fires when the chain is changed
   * Ensures the chainInfo is updated then updates the balance we see.
   */
  async onChainChanged () : Promise<void> {
    this.badConfiguration = null;

    // There is a case where the auto-reconnect didn't work on initialization
    // because the provider was not available yet.  This will cause a chain change
    // event once it becomes available, so try auto-connection when this happens
    if (!EthereumProvider.selectedAddress()) {
      await this._automaticallyReconnect();
    }

    this.updateChainInfo();
    await this.updateBalance();
  }

  /**
   * Fires when the provider is changed
   * Ensures that the chainInfo is updated then automatically reconnnects.
   */
  async onProviderChanged () : Promise<void> {
    this.badConfiguration = null;
    this.updateChainInfo();
    // TODO: This is not guaranteed. If (EP._network && !EP._fallbackProvider)
    //       and the wallet is on the wrong network it will fail.
    this.providerAvailable = true;
    await this._automaticallyReconnect();
  }

  /*
   * Helpful in every view for when users need to install a wallet
   */
  openMetamaskLink () : void {
    window.open('https://metamask.io', '_blank');
  }
}
