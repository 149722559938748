import MConnect from '@/exports/MConnect.vue';
import { renderComponentWithApp } from '../build/mount';

// For usage outside of a Vue application but still in JS:
let mConnectionInterval!: number;
if (window) {
  window.addEventListener('load', () => {
    let timeout = 10;
    if (!mConnectionInterval) {
      mConnectionInterval = window.setInterval(() => {
        timeout--;

        // Grab all empty widget containers from the DOM
        const elements = document.querySelectorAll("[data-widget='m-connect']");

        if (timeout === 0) {
          clearInterval(mConnectionInterval);
          console.error('MConnect failed to mount due to time out.');
          return;
        }

        if (elements.length) {
          // No need to keep checking for elements as we've found them all.
          clearInterval(mConnectionInterval);

          for (let i = 0; i < elements.length; i++) {
            const el = elements[i];
            // grab the DOM element's data- properties to use as propsData
            const data = (el as HTMLElement).dataset;

            // be sure to give proper fallback type
            const propsData = {
              fallbackProvider: ('fallbackProvider' in data) ? data.fallbackProvider : undefined,
              walletProvider: 'walletProvider' in data ? data.walletProvider : undefined,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              network: ('network' in data) ? parseInt(data.network!) : undefined,
              avatar: ('avatar' in data) ? data.avatar : undefined,
              multi: ('multi' in data) ? !!data.multi : false,
              minimal: ('minimal' in data) ? !!data.minimal : false,
              autoReconnect: ('autoReconnect' in data) ? !!data.autoReconnect : true,
              strictAuth: ('strictAuth' in data) ? !!data.strictAuth : true,
              delayAuth: ('delayAuth' in data) ? !!data.delayAuth : false,
              message: ('message' in data) ? data.message : undefined,
              showChain: ('showChain' in data),
              showBalance: ('showBalance' in data),
              clientId: ('clientId' in data) ? data.clientId : undefined,
              appName: ('appName' in data) ? data.appName : undefined,
              grantType: ('grantType' in data) ? data.grantType : undefined,
              redirectUri: ('redirectUri' in data) ? data.redirectUri : undefined,
              overrideConnectText: ('overrideConnectText' in data) ? data.overrideConnectText : undefined,
              connectWalletImage: ('connectWalletImage' in data) ? data.connectWalletImage : undefined
            };

            renderComponentWithApp({
              el: el,
              component: MConnect,
              props: propsData
            });
          }
        }
      }, 1000);
    }
  });
}
