import { ethers } from 'ethers';

export const AUTHENTICATED = 'm-authenticated';
export const UNAUTHENTICATED = 'm-unauthenticated';

export const ETHEREUM_NETWORK_COLORS: Record<number, string> = {
  1: '#59B3AE',
  3: '#EC5A8C',
  4: '#EFC45B',
  42: '#886BF6',
  137: '#7B4ADD',
  6284: '#4D99EB'
};

export const ETHEREUM_NETWORK_NAMES: Record<number, string> = {
  1: 'MAINNET',
  3: 'ROPSTEN',
  4: 'RINKEBY',
  42: 'KOVAN',
  137: 'MATIC',
  6284: 'GOERLI'
};

export interface AbstractProvider extends ethers.providers.Web3Provider {
  provider: {
    isCoinbaseWallet: boolean;
    isBraveWallet: boolean;
    isLedgerConnect: boolean;
    isMetaMask: boolean;
    disconnect: () => void;
  }
}
