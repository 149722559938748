import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  type: "button"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "m-connection-avatar"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 1,
  class: "m-connection-balance"
}
const _hoisted_6 = {
  key: 3,
  class: "m-connection-wrong-network"
}
const _hoisted_7 = { class: "m-connection-wallet" }
const _hoisted_8 = {
  key: 0,
  class: "m-connection-dropdown"
}
const _hoisted_9 = ["disabled"]
const _hoisted_10 = ["src"]
const _hoisted_11 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: "m-connection",
    class: _normalizeClass(["manifold", {connected: _ctx.walletConnected, menu: _ctx.showDisconnectMenu}])
  }, [
    (!_ctx.walletAvailable)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "m-connection-install-wallet",
          type: "button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openMetamaskLink && _ctx.openMetamaskLink(...args)))
        }, " Install Wallet "))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.badConfiguration)
            ? (_openBlock(), _createElementBlock("button", _hoisted_1, _toDisplayString(_ctx.badConfiguration), 1))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_ctx.walletConnected)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createElementVNode("button", {
                        type: "button",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDisconnectMenu = !_ctx.showDisconnectMenu))
                      }, [
                        (_ctx.avatar)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                              _createElementVNode("img", {
                                class: "m-connection-avatar-img",
                                src: _ctx.avatar
                              }, null, 8, _hoisted_4)
                            ]))
                          : _createCommentVNode("", true),
                        (!_ctx.wrongChain && _ctx.showBalance)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.walletBalance) + " ETH", 1))
                          : _createCommentVNode("", true),
                        (!_ctx.wrongChain && _ctx.showChain && _ctx.chainInfo)
                          ? (_openBlock(), _createElementBlock("span", {
                              key: 2,
                              class: "m-connection-chain-id",
                              style: _normalizeStyle({'background-color': _ctx.chainInfo.color})
                            }, null, 4))
                          : _createCommentVNode("", true),
                        (_ctx.wrongChain)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_6, "Wrong network"))
                          : _createCommentVNode("", true),
                        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.walletENS ? _ctx.walletENS : _ctx.walletAddressShort), 1)
                      ]),
                      (_ctx.showDisconnectMenu)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _renderSlot(_ctx.$slots, "dropdown"),
                            _createElementVNode("button", {
                              class: "m-connection-disconnect-wallet",
                              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.disconnectAndClose && _ctx.disconnectAndClose(...args)))
                            }, [
                              _createElementVNode("span", {
                                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showDisconnectMenu = !_ctx.showDisconnectMenu))
                              }, "Disconnect ")
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      type: "button",
                      class: "m-connection-connect-wallet",
                      disabled: _ctx.isLoading,
                      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.connectWallet && _ctx.connectWallet(...args)))
                    }, [
                      (_ctx.connectWalletImage)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            id: "connect-wallet-image",
                            src: _ctx.connectWalletImage
                          }, null, 8, _hoisted_10))
                        : (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.buttonText), 1))
                    ], 8, _hoisted_9))
              ], 64))
        ], 64))
  ], 2))
}